/** Build environment */
export const BUILD_ENV = process.env.NODE_ENV;
export const IS_DEVELOPMENT = BUILD_ENV === 'development';
export const IS_TEST = BUILD_ENV === 'test';
export const IS_PRODUCTION = BUILD_ENV === 'production';

/** Permissions */
export const ALLOW_DEV_CONSOLE = process.env.REACT_APP_ALLOW_DEV_CONSOLE === 'true';
export const ALLOW_REDUX_DEVTOOLS_EXTENSION =
	process.env.REACT_APP_ALLOW_REDUX_DEVTOOLS_EXTENSION === 'true';
export const ALLOW_WINDOW_EXTENDS = process.env.REACT_APP_ALLOW_WINDOW_EXTENDS === 'true';

/** App */
export const APP_NAME = process.env.REACT_APP_NAME;
export const STORAGE_PREFIX = process.env.REACT_APP_STORAGE_PREFIX;

/** API */
export const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;
export const WEB_API_URL_STAGE = process.env.REACT_APP_WEB_API_URL_STAGE;
export const WEB_API_URL_QA = process.env.REACT_APP_WEB_API_URL_QA;
export const WEB_API_URL_MR = process.env.REACT_APP_WEB_API_URL_MR;
export const WEB_SOKET_API_URL = process.env.REACT_APP_WEB_SOKET_API_URL;
export const WEB_SOKET_API_URL_STAGE = process.env.REACT_APP_WEB_SOKET_API_URL_STAGE;
export const WEB_SOKET_API_URL_QA = process.env.REACT_APP_WEB_SOKET_API_URL_QA;
export const GET_PHOTO = process.env.REACT_APP_GET_PHOTO;
export const { GOOGLE_API_KEY } = process.env;
